import {
  FETCH_ADD_CITY,
  SET_FETCH_ADD_CITY_RESULT,
  SET_FETCH_ADD_CITY_FAILURE,
  FETCH_UPDATE_CITY,
  SET_FETCH_UPDATE_CITY_RESULT,
  SET_FETCH_UPDATE_CITY_FAILURE,
  SET_CITY_DETAILS_RESULT,
  SET_CITY_DETAILS_FAILURE,
  FETCH_GET_CITY_DETAILS,
  SET_CITIES_NAMES_FAILURE,
  FETCH_GET_CITIES_NAMES,
  FETCH_GET_CURRENT_CITY_DETAILS,
  SET_CURRENT_CITY_DETAILS_RESULT,
  SET_CURRENT_CITY_DETAILS_FAILURE,
  FETCH_DELETE_CITY,
  SET_FETCH_DELETE_CITY_RESULT,
  SET_FETCH_DELETE_CITY_FAILURE,
  FETCH_REORDER_CITY_EQUIPMENTS,
  SET_REORDER_CITY_EQUIPMENTS_RESULT,
  SET_REORDER_CITY_EQUIPMENTS_FAILURE,
  FETCH_REGISTER_CITY,
  SET_REGISTER_CITY_RESULT,
  SET_REGISTER_CITY_FAILURE,
  SET_CITIES_NAMES,
  FETCH_GET_NEAR_CITIES,
  SET_NEAR_CITIES,
  SET_NEAR_CITIES_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";
import { fetchGetChannels } from "./channel";

const equipment = new schema.Entity(
  "equipments",
  {},
  {
    idAttribute: "_id"
  }
);

const channel = new schema.Entity(
  "channels",
  {},
  {
    idAttribute: "_id"
  }
);

export const city = new schema.Entity(
  "city",
  {
    channelsIds: [channel],
    equipmentsIds: [equipment]
  },
  {
    idAttribute: "_id"
  }
);

const cities = new schema.Entity(
  "citiesNames",
  {},
  {
    idAttribute: "_id"
  }
);

const infospage = new schema.Entity("infospages", {
  docs: [cities]
});

export function fetchGetCitiesNames(page = 1, pageSize = 5, sorted = []) {
  return apiAction({
    url:
      "/communities?communitytype=City&page=" +
      page +
      "&pageSize=" +
      pageSize +
      (sorted.length > 0
        ? "&sorted=" +
          sorted.map(elt => elt.id + (elt.desc ? ":-1" : ":1")).join("|")
        : ""),
    method: "GET",
    schema: infospage,
    onSuccess: setCitiesNames,
    onFailure: setCitiesNamesFailure,
    label: FETCH_GET_CITIES_NAMES
  });
}

function setCitiesNames(normalized) {
  return {
    type: SET_CITIES_NAMES,
    entities: normalized.entities
  };
}

function setCitiesNamesFailure(error) {
  return {
    type: SET_CITIES_NAMES_FAILURE,
    payload: error
  };
}

export function fetchGetNearCities(longitude = 0, latitude = 0, channelTypes) {
  return apiAction({
    url: "/cities/near",
    method: "GET",
    schema: [cities],
    data: longitude && latitude ? { longitude, latitude } : null,
    onSuccess: normalized => [
      setNearCitiesResult(normalized),
      fetchGetChannels(
        normalized.entities.citiesNames
          ? Object.keys(normalized.entities.citiesNames)
          : [],
        channelTypes
      )
    ],
    onFailure: setNearCitiesFailure,
    label: FETCH_GET_NEAR_CITIES
  });
}

function setNearCitiesResult(normalized) {
  return {
    type: SET_NEAR_CITIES,
    entities: normalized.entities
  };
}

function setNearCitiesFailure(error) {
  return {
    type: SET_NEAR_CITIES_FAILURE,
    payload: error
  };
}

export function fetchGetCurrentCityDetails() {
  return apiAction({
    url: "/cities/city",
    method: "GET",
    schema: city,
    onSuccess: setCurrentCityDetailsResult,
    onFailure: setCurrentCityDetailsFailure,
    label: FETCH_GET_CURRENT_CITY_DETAILS
  });
}

function setCurrentCityDetailsResult(normalized) {
  return {
    type: SET_CURRENT_CITY_DETAILS_RESULT,
    entities: normalized.entities
  };
}

function setCurrentCityDetailsFailure(error) {
  return {
    type: SET_CURRENT_CITY_DETAILS_FAILURE,
    error
  };
}

export function fetchGetCityDetails(id) {
  return apiAction({
    url: "/cities/city/" + id,
    method: "GET",
    schema: city,
    onSuccess: normalized => [
      setCityDetailsResult(normalized),
      fetchGetChannels(
        [id],
        ["CityGooddealChannel", "CityEventChannel", "WeatherChannel"]
      )
    ],
    onFailure: setCityDetailsFailure,
    label: FETCH_GET_CITY_DETAILS
  });
}

function setCityDetailsResult(normalized) {
  return {
    type: SET_CITY_DETAILS_RESULT,
    entities: normalized.entities
  };
}

function setCityDetailsFailure(error) {
  return {
    type: SET_CITY_DETAILS_FAILURE,
    error
  };
}

export function fetchDeleteCity(id, name) {
  return apiAction({
    url: "/cities/city/" + id + "?name=" + name,
    method: "DELETE",
    onSuccess: setDeleteCityResult,
    onFailure: setDeleteCityFailure,
    label: FETCH_DELETE_CITY
  });
}

function setDeleteCityResult(data) {
  return {
    type: SET_FETCH_DELETE_CITY_RESULT,
    payload: data
  };
}

function setDeleteCityFailure(error) {
  return {
    type: SET_FETCH_DELETE_CITY_FAILURE,
    error
  };
}

export function fetchAddCity(city) {
  return apiAction({
    url: "/cities",
    method: "POST",
    data: city,
    onSuccess: setFetchAddCityResult,
    onFailure: setFetchAddCityFailure,
    label: FETCH_ADD_CITY
  });
}

function setFetchAddCityResult(data) {
  return {
    type: SET_FETCH_ADD_CITY_RESULT,
    payload: data
  };
}

function setFetchAddCityFailure(error) {
  return {
    type: SET_FETCH_ADD_CITY_FAILURE,
    error
  };
}

export function fetchUpdateCity(city) {
  return apiAction({
    url: "/cities/city/" + city._id,
    method: "PUT",
    data: city,
    onSuccess: setFetchUpdateCityResult,
    onFailure: setFetchUpdateCityFailure,
    label: FETCH_UPDATE_CITY
  });
}

function setFetchUpdateCityResult(data) {
  return {
    type: SET_FETCH_UPDATE_CITY_RESULT,
    payload: data
  };
}

function setFetchUpdateCityFailure(error) {
  return {
    type: SET_FETCH_UPDATE_CITY_FAILURE,
    error
  };
}

export function fetchReorderCityEquipments(communityId, equipmentsIds) {
  return apiAction({
    url: "/cities/city/" + communityId + "/equipments",
    method: "PUT",
    data: { equipmentsIds },
    onSuccess: setReorderCityEquipmentsResult,
    onFailure: setReorderCityEquipmentsFailure,
    label: FETCH_REORDER_CITY_EQUIPMENTS
  });
}

function setReorderCityEquipmentsResult(data) {
  return {
    type: SET_REORDER_CITY_EQUIPMENTS_RESULT,
    payload: data
  };
}

function setReorderCityEquipmentsFailure(error) {
  return {
    type: SET_REORDER_CITY_EQUIPMENTS_FAILURE,
    error
  };
}

export function fetchRegisterCity(data) {
  return apiAction({
    url: "/cities/register",
    method: "POST",
    data,
    onSuccess: setRegisterCityResult,
    onFailure: setRegisterCityFailure,
    label: FETCH_REGISTER_CITY
  });
}

function setRegisterCityResult(data) {
  return {
    type: SET_REGISTER_CITY_RESULT,
    payload: data
  };
}

function setRegisterCityFailure(error) {
  return {
    type: SET_REGISTER_CITY_FAILURE,
    error
  };
}
