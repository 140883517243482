// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput";

import { SET_LOCATION_NAME_EN } from "actions/types";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  ...customInputStyle
};

class Step2 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    return true;
  }

  render() {
    const { dispatch, lang, isFetching } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Nom"
            id="name-en"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: lang && lang.en ? lang.en.name : "",
              onChange: e =>
                dispatch({
                  type: SET_LOCATION_NAME_EN,
                  payload: e.target.value
                })
            }}
            disabled={isFetching}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const { isFetching, lang } = state.locationReducer;

  return {
    isFetching,
    lang
  };
};

export default connect(mapStateToProps)(withStyles(style)(Step2));
