import {
  FETCH_ADD_CAMPSITE,
  SET_FETCH_ADD_CAMPSITE_RESULT,
  SET_FETCH_ADD_CAMPSITE_FAILURE,
  FETCH_UPDATE_CAMPSITE,
  SET_FETCH_UPDATE_CAMPSITE_RESULT,
  SET_FETCH_UPDATE_CAMPSITE_FAILURE,
  SET_CAMPSITE_DETAILS_RESULT,
  SET_CAMPSITE_DETAILS_FAILURE,
  FETCH_GET_CAMPSITE_DETAILS,
  SET_CAMPSITES_FAILURE,
  FETCH_GET_CAMPSITES,
  FETCH_GET_CURRENT_CAMPSITE_DETAILS,
  SET_CURRENT_CAMPSITE_DETAILS_RESULT,
  SET_CURRENT_CAMPSITE_DETAILS_FAILURE,
  FETCH_DELETE_CAMPSITE,
  SET_FETCH_DELETE_CAMPSITE_RESULT,
  SET_FETCH_DELETE_CAMPSITE_FAILURE,
  FETCH_REGISTER_CAMPSITE,
  SET_REGISTER_CAMPSITE_RESULT,
  SET_REGISTER_CAMPSITE_FAILURE,
  SET_CAMPSITES_NAMES
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";
import { fetchGetNearCities } from "./city";

const equipment = new schema.Entity(
  "equipments",
  {},
  {
    idAttribute: "_id"
  }
);

const city = new schema.Entity(
  "cities",
  {},
  {
    idAttribute: "_id"
  }
);

const channel = new schema.Entity(
  "channels",
  {},
  {
    idAttribute: "_id"
  }
);

export const campsite = new schema.Entity(
  "campsite",
  {
    channelsIds: [channel],
    equipmentsIds: [equipment],
    citiesIds: [city]
  },
  {
    idAttribute: "_id"
  }
);

const campsites = new schema.Entity(
  "campsitesNames",
  {},
  {
    idAttribute: "_id"
  }
);

const infospage = new schema.Entity("infospages", {
  docs: [campsites]
});

export function fetchGetCampsites(page = 1, pageSize = 5, sorted = []) {
  return apiAction({
    url:
      "/communities?communitytype=Campsite&page=" +
      page +
      "&pageSize=" +
      pageSize +
      (sorted.length > 0
        ? "&sorted=" +
          sorted.map(elt => elt.id + (elt.desc ? ":-1" : ":1")).join("|")
        : ""),
    method: "GET",
    schema: infospage,
    onSuccess: setCampsites,
    onFailure: setCampsitesFailure,
    label: FETCH_GET_CAMPSITES
  });
}

function setCampsites(normalized) {
  return {
    type: SET_CAMPSITES_NAMES,
    entities: normalized.entities
  };
}

function setCampsitesFailure(data) {
  return {
    type: SET_CAMPSITES_FAILURE,
    payload: data
  };
}

export function fetchGetCurrentCampsiteDetails() {
  return apiAction({
    url: "/campsites/campsite",
    method: "GET",
    schema: campsite,
    onSuccess: setCurrentCampsiteDetailsResult,
    onFailure: setCurrentCampsiteDetailsFailure,
    label: FETCH_GET_CURRENT_CAMPSITE_DETAILS
  });
}

function setCurrentCampsiteDetailsResult(normalized) {
  return {
    type: SET_CURRENT_CAMPSITE_DETAILS_RESULT,
    entities: normalized.entities
  };
}

function setCurrentCampsiteDetailsFailure(error) {
  return {
    type: SET_CURRENT_CAMPSITE_DETAILS_FAILURE,
    error
  };
}

export function fetchGetCampsiteDetails(id) {
  return apiAction({
    url: "/campsites/campsite/" + id,
    method: "GET",
    schema: campsite,
    onSuccess: normalized => {
      const campsite = Object.values(normalized.entities.campsite)[0];
      const [longitude, latitude] = campsite.loc.coordinates;
      return [
        setCampsiteDetailsResult(normalized),
        fetchGetNearCities(longitude, latitude, [
          "CampsiteGooddealChannel",
          "CampsiteEventChannel",
          "WeatherChannel"
        ])
      ];
    },
    onFailure: setCampsiteDetailsFailure,
    label: FETCH_GET_CAMPSITE_DETAILS
  });
}

function setCampsiteDetailsResult(normalized) {
  return {
    type: SET_CAMPSITE_DETAILS_RESULT,
    entities: normalized.entities
  };
}

function setCampsiteDetailsFailure(error) {
  return {
    type: SET_CAMPSITE_DETAILS_FAILURE,
    error
  };
}

export function fetchDeleteCampsite(id, name) {
  return apiAction({
    url: "/campsites/campsite/" + id + "?name=" + name,
    method: "DELETE",
    onSuccess: setDeleteCampsiteResult,
    onFailure: setDeleteCampsiteFailure,
    label: FETCH_DELETE_CAMPSITE
  });
}

function setDeleteCampsiteResult(data) {
  return {
    type: SET_FETCH_DELETE_CAMPSITE_RESULT,
    payload: data
  };
}

function setDeleteCampsiteFailure(error) {
  return {
    type: SET_FETCH_DELETE_CAMPSITE_FAILURE,
    error
  };
}

export function fetchAddCampsite(campsite) {
  return apiAction({
    url: "/campsites",
    method: "POST",
    data: campsite,
    onSuccess: setFetchAddCampsiteResult,
    onFailure: setFetchAddCampsiteFailure,
    label: FETCH_ADD_CAMPSITE
  });
}

function setFetchAddCampsiteResult(data) {
  return {
    type: SET_FETCH_ADD_CAMPSITE_RESULT,
    payload: data
  };
}

function setFetchAddCampsiteFailure(error) {
  return {
    type: SET_FETCH_ADD_CAMPSITE_FAILURE,
    error
  };
}

export function fetchUpdateCampsite(campsite) {
  return apiAction({
    url: "/campsites/campsite/" + campsite._id,
    method: "PUT",
    data: campsite,
    onSuccess: setFetchUpdateCampsiteResult,
    onFailure: setFetchUpdateCampsiteFailure,
    label: FETCH_UPDATE_CAMPSITE
  });
}

function setFetchUpdateCampsiteResult(data) {
  return {
    type: SET_FETCH_UPDATE_CAMPSITE_RESULT,
    payload: data
  };
}

function setFetchUpdateCampsiteFailure(error) {
  return {
    type: SET_FETCH_UPDATE_CAMPSITE_FAILURE,
    error
  };
}

export function fetchRegisterCampsite(data) {
  return apiAction({
    url: "/campsites/register",
    method: "POST",
    data,
    onSuccess: setRegisterCampsiteResult,
    onFailure: setRegisterCampsiteFailure,
    label: FETCH_REGISTER_CAMPSITE
  });
}

function setRegisterCampsiteResult(data) {
  return {
    type: SET_REGISTER_CAMPSITE_RESULT,
    payload: data
  };
}

function setRegisterCampsiteFailure(error) {
  return {
    type: SET_REGISTER_CAMPSITE_FAILURE,
    error
  };
}
