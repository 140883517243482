// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormHelperText from "@material-ui/core/FormHelperText";

import {
  REMOVE_NEIGHBORHOOD_IMAGE,
  SET_NEIGHBORHOOD_FILES_IMAGES,
  REMOVE_NEIGHBORHOODMAP_IMAGE,
  SET_NEIGHBORHOODMAP_FILES_IMAGES,
  SET_NEIGHBORHOOD_NAME,
  SET_NEIGHBORHOOD_NAME_ERROR,
  SET_NEIGHBORHOOD_REGISTER_KEY,
  DELETE_NEIGHBORHOOD_CHANNEL,
  ADD_NEIGHBORHOOD_CHANNEL,
  ADD_NEIGHBORHOOD_CITY,
  DELETE_NEIGHBORHOOD_CITY
} from "actions/types";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
const ReactTags = require("react-tag-autocomplete");

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  tags: {
    margin: "20px",
    width: "100%"
  },
  ...customInputStyle,
  ...customSelectStyle
};

class Step1 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    const { dispatch, name } = this.props;

    let success = true;
    if (!name || name.trim().length === 0) {
      dispatch({
        type: SET_NEIGHBORHOOD_NAME_ERROR
      });
      success = false;
    }
    return success;
  }

  onImageLoaded = (name, imagePreviewUrl) => {
    const { dispatch } = this.props;
    dispatch({
      type: SET_NEIGHBORHOOD_FILES_IMAGES,
      payload: [{ name, base64: imagePreviewUrl, out: "logoUri" }]
    });
  };

  onImageRemoved = () => {
    this.props.dispatch({
      type: REMOVE_NEIGHBORHOOD_IMAGE
    });
  };

  onNeighborhoodMapLoaded = (name, imagePreviewUrl) => {
    const { dispatch } = this.props;
    dispatch({
      type: SET_NEIGHBORHOODMAP_FILES_IMAGES,
      payload: [
        {
          name,
          base64: imagePreviewUrl,
          ignoreQualityTransform: true,
          out: "mapUri"
        },
        { name: name + "-small", base64: imagePreviewUrl, out: "mapSmallUri" }
      ]
    });
  };

  onNeighborhoodMapRemoved = () => {
    this.props.dispatch({
      type: REMOVE_NEIGHBORHOODMAP_IMAGE
    });
  };

  handleDeleteCity = index => {
    const { dispatch } = this.props;

    if (index >= 0) {
      dispatch({
        type: DELETE_NEIGHBORHOOD_CITY,
        index
      });
    }
  };

  handleAdditionCity = city => {
    const { dispatch } = this.props;
    dispatch({
      type: ADD_NEIGHBORHOOD_CITY,
      value: city
    });
  };

  handleDelete = index => {
    const { dispatch } = this.props;

    if (index >= 0) {
      dispatch({
        type: DELETE_NEIGHBORHOOD_CHANNEL,
        index
      });
    }
  };

  handleAddition = channel => {
    const { dispatch } = this.props;
    dispatch({
      type: ADD_NEIGHBORHOOD_CHANNEL,
      value: channel
    });
  };

  render() {
    const {
      classes,
      dispatch,
      name,
      nameErrorMsg,
      logoUri,
      mapSmallUri,
      registerkey,
      channels,
      channelsSuggestions,
      citiesTags,
      citiesSuggestions
    } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={4} md={4}>
          <legend>Logo</legend>
          <ImageUpload
            ref={this.child}
            onImageLoaded={this.onImageLoaded}
            onImageRemoved={this.onImageRemoved}
            photoUri={logoUri}
            addButtonProps={{
              color: "brown",
              round: true
            }}
            changeButtonProps={{
              color: "brown",
              round: true
            }}
            removeButtonProps={{
              color: "danger",
              round: true
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <legend>Plan intéractif</legend>
          <ImageUpload
            ref={this.child}
            onImageLoaded={this.onNeighborhoodMapLoaded}
            onImageRemoved={this.onNeighborhoodMapRemoved}
            photoUri={mapSmallUri}
            addButtonProps={{
              color: "brown",
              round: true
            }}
            changeButtonProps={{
              color: "brown",
              round: true
            }}
            removeButtonProps={{
              color: "danger",
              round: true
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Nom *"
            id="name"
            formControlProps={{
              fullWidth: true
            }}
            error={nameErrorMsg !== null}
            helperText={nameErrorMsg}
            inputProps={{
              value: name ? name : "",
              onChange: e =>
                dispatch({
                  type: SET_NEIGHBORHOOD_NAME,
                  value: e.target.value
                })
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Clé pour rejoindre la communauté de voisinage"
            id="registerkey"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: registerkey ? registerkey : "",
              onChange: e =>
                dispatch({
                  type: SET_NEIGHBORHOOD_REGISTER_KEY,
                  value: e.target.value
                })
            }}
          />
        </GridItem>
        <div className={classes.tags}>
          <FormHelperText>{"Villes rattachées à la communauté"}</FormHelperText>
          <ReactTags
            placeholderText="Ville(s) ..."
            tags={citiesTags}
            suggestions={citiesSuggestions}
            onDelete={this.handleDeleteCity}
            onAddition={this.handleAdditionCity}
            removeButtonText="Cliquez pour retirer"
          />
        </div>
        <div className={classes.tags}>
          <FormHelperText>
            {
              "Canaux de diffusion depuis lesquelles recevoir les publications et bons plans"
            }
          </FormHelperText>
          <ReactTags
            placeholderText="Canaux de diffusion ..."
            tags={channels}
            suggestions={channelsSuggestions}
            onDelete={this.handleDelete}
            onAddition={this.handleAddition}
            removeButtonText="Cliquez pour retirer"
          />
        </div>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    name,
    nameErrorMsg,
    logoUri,
    mapSmallUri,
    registerkey,
    channels,
    citiesTags
  } = state.neighborhoodReducer;
  return {
    name,
    nameErrorMsg,
    logoUri,
    mapSmallUri,
    registerkey,
    channels: Object.values(channels).filter(obj => obj.selected),
    channelsSuggestions: Object.values(channels).filter(obj => !obj.selected),
    citiesTags: Object.values(citiesTags).filter(obj => obj.selected),
    citiesSuggestions: Object.values(citiesTags).filter(obj => !obj.selected)
  };
};

export default connect(mapStateToProps)(withStyles(style)(Step1));
