// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormHelperText from "@material-ui/core/FormHelperText";

import {
  REMOVE_CITY_IMAGE,
  SET_CITY_FILES_IMAGES,
  REMOVE_CITYMAP_IMAGE,
  SET_CITYMAP_FILES_IMAGES,
  SET_CITY_NAME,
  SET_CITY_NAME_ERROR,
  SET_CITY_REGISTER_KEY,
  DELETE_CITY_CHANNEL,
  ADD_CITY_CHANNEL,
  SET_CITY_CUSTOM_URI,
  SET_CITY_CUSTOM_URI_ERROR,
  SET_CITY_LOGO_FILES_IMAGES,
  REMOVE_CITY_LOGO_IMAGE
} from "actions/types";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
const ReactTags = require("react-tag-autocomplete");

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  tags: {
    margin: "20px",
    width: "100%"
  },
  ...customInputStyle,
  ...customSelectStyle
};

class Step1 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    const { dispatch, name, customUri } = this.props;

    let success = true;
    if (!name || name.trim().length === 0) {
      dispatch({
        type: SET_CITY_NAME_ERROR
      });
      success = false;
    }
    if (!customUri || customUri.trim().length === 0) {
      dispatch({
        type: SET_CITY_CUSTOM_URI_ERROR
      });
      success = false;
    }
    return success;
  }

  onImageLoaded = (name, imagePreviewUrl) => {
    const { dispatch } = this.props;
    dispatch({
      type: SET_CITY_FILES_IMAGES,
      payload: [{ name, base64: imagePreviewUrl, out: "photoUri" }]
    });
  };

  onImageRemoved = () => {
    this.props.dispatch({
      type: REMOVE_CITY_IMAGE
    });
  };

  onLogoLoaded = (name, imagePreviewUrl) => {
    const { dispatch } = this.props;
    dispatch({
      type: SET_CITY_LOGO_FILES_IMAGES,
      payload: [{ name, base64: imagePreviewUrl, out: "logoUri" }]
    });
  };

  onLogoRemoved = () => {
    this.props.dispatch({
      type: REMOVE_CITY_LOGO_IMAGE
    });
  };

  onCityMapLoaded = (name, imagePreviewUrl) => {
    const { dispatch } = this.props;
    dispatch({
      type: SET_CITYMAP_FILES_IMAGES,
      payload: [
        {
          name,
          base64: imagePreviewUrl,
          ignoreQualityTransform: true,
          out: "mapUri"
        },
        { name: name + "-small", base64: imagePreviewUrl, out: "mapSmallUri" }
      ]
    });
  };

  onCityMapRemoved = () => {
    this.props.dispatch({
      type: REMOVE_CITYMAP_IMAGE
    });
  };

  handleDelete = index => {
    const { dispatch } = this.props;

    if (index >= 0) {
      dispatch({
        type: DELETE_CITY_CHANNEL,
        value: index
      });
    }
  };

  handleAddition = channel => {
    const { dispatch } = this.props;
    dispatch({
      type: ADD_CITY_CHANNEL,
      value: channel
    });
  };

  render() {
    const {
      classes,
      dispatch,
      name,
      nameErrorMsg,
      customUri,
      customUriErrorMsg,
      photoUri,
      logoUri,
      mapSmallUri,
      registerkey,
      channels,
      channelsSuggestions
    } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={4} md={4}>
          <legend>Couverture</legend>
          <ImageUpload
            onImageLoaded={this.onImageLoaded}
            onImageRemoved={this.onImageRemoved}
            photoUri={photoUri}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <legend>Logo</legend>
          <ImageUpload
            onImageLoaded={this.onLogoLoaded}
            onImageRemoved={this.onLogoRemoved}
            photoUri={logoUri}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <legend>Plan intéractif</legend>
          <ImageUpload
            onImageLoaded={this.onCityMapLoaded}
            onImageRemoved={this.onCityMapRemoved}
            photoUri={mapSmallUri}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Nom *"
            id="name"
            formControlProps={{
              fullWidth: true
            }}
            error={nameErrorMsg !== null}
            helperText={nameErrorMsg}
            inputProps={{
              value: name ? name : "",
              onChange: e =>
                dispatch({
                  type: SET_CITY_NAME,
                  value: e.target.value
                })
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="URI *"
            id="uri"
            formControlProps={{
              fullWidth: true
            }}
            error={customUriErrorMsg !== null}
            helperText={customUriErrorMsg}
            inputProps={{
              value: customUri ? customUri : "",
              onChange: e =>
                dispatch({
                  type: SET_CITY_CUSTOM_URI,
                  value: e.target.value
                })
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Clé de la ville pour rejoindre la communauté"
            id="registerkey"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: registerkey ? registerkey : "",
              onChange: e =>
                dispatch({
                  type: SET_CITY_REGISTER_KEY,
                  value: e.target.value
                })
            }}
          />
        </GridItem>
        <div className={classes.tags}>
          <FormHelperText>
            {
              "Canaux de diffusion depuis lesquelles recevoir les publications et bons plans"
            }
          </FormHelperText>
          <ReactTags
            placeholderText="Canaux de diffusion ..."
            tags={channels}
            suggestions={channelsSuggestions}
            onDelete={this.handleDelete}
            onAddition={this.handleAddition}
            removeButtonText="Cliquez pour retirer"
          />
        </div>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    name,
    nameErrorMsg,
    logoUri,
    mapSmallUri,
    registerkey,
    channels,
    customUri,
    customUriErrorMsg,
    photoUri
  } = state.cityReducer;
  return {
    name,
    nameErrorMsg,
    logoUri,
    mapSmallUri,
    registerkey,
    channels: Object.values(channels).filter(obj => obj.selected),
    channelsSuggestions: Object.values(channels).filter(obj => !obj.selected),
    customUri,
    customUriErrorMsg,
    photoUri
  };
};

export default connect(mapStateToProps)(withStyles(style)(Step1));
