// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormHelperText from "@material-ui/core/FormHelperText";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
// import GoogleMapHandlerComponent from "views/Maps/GoogleMapHandlerComponent";
import {
  ADD_GOODDEAL_CHANNEL,
  DELETE_GOODDEAL_CHANNEL,
  MISSING_CHANNEL
} from "actions/types.js";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
const ReactTags = require("react-tag-autocomplete");

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  tags: {
    margin: "20px",
    width: "100%"
  },
  ...customInputStyle
};

class Step6 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    const { dispatch, channels } = this.props;
    if (channels.length === 0) {
      dispatch({
        type: MISSING_CHANNEL
      });
    }
    return channels.length > 0;
  }

  handleDelete = index => {
    const { dispatch } = this.props;

    if (index >= 0) {
      dispatch({
        type: DELETE_GOODDEAL_CHANNEL,
        index
      });
    }
  };

  handleAddition = channel => {
    const { dispatch } = this.props;
    dispatch({
      type: ADD_GOODDEAL_CHANNEL,
      value: channel
    });
  };

  render() {
    const {
      classes,
      // suggestions,
      // publishedCities,
      // isFetching,
      // publishedCityErrorMsg,
      // dispatch,
      channels,
      channelsSuggestions,
      publishedChannelErrorMsg
    } = this.props;

    // const mapsComponent = isFetching ? null : (
    //   <GoogleMapHandlerComponent
    //     location={{
    //       markers: suggestions
    //     }}
    //     classes={classes}
    //     dispatch={dispatch}
    //     zoom={10}
    //     addProp={ADD_GOODDEAL_CITY}
    //   />
    // );

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            {
              "Veuillez sélectionner le ou les canaux de diffusion où publier votre annonce"
            }
          </h4>
        </GridItem>
        <GridItem xs={12} sm={12}>
          {/* <div
            style={{
              height: `480px`,
              borderRadius: "6px",
              overflow: "hidden"
            }}
          >
            {mapsComponent}
          </div> */}
          <div style={{ marginTop: "20px" }}>
            <ReactTags
              placeholderText="Canaux de diffusion ..."
              tags={channels}
              suggestions={channelsSuggestions}
              onDelete={this.handleDelete}
              onAddition={this.handleAddition}
              removeButtonText="Cliquez pour retirer"
            />
            {publishedChannelErrorMsg && (
              <FormHelperText className={classes.labelRootError}>
                {publishedChannelErrorMsg}
              </FormHelperText>
            )}
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

Step6.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const {
    isFetching,
    publishedCities,
    publishedCityErrorMsg,
    suggestions,
    channels,
    publishedChannelErrorMsg
  } = state.gooddealReducer;
  return {
    suggestions,
    publishedCities,
    publishedCityErrorMsg,
    isFetching,
    channels: Object.values(channels).filter(obj => obj.selected),
    channelsSuggestions: Object.values(channels).filter(obj => !obj.selected),
    publishedChannelErrorMsg
  };
};

export default connect(mapStateToProps)(withStyles(style)(Step6));
