import {
  ADD_GOODDEAL_CAMPSITE,
  DELETE_GOODDEAL_CAMPSITE,
  MISSING_CAMPSITE,
  API_START,
  API_END,
  SET_LOCALIZATION,
  SET_GOODDEALS_FILES_IMAGES,
  REMOVE_GOODDEALS_IMAGE,
  SET_WEB_SITE_URL,
  CLEAR_WEB_SITE_URL,
  SET_GOODDEALS_CATEGORY,
  SET_GOODDEALS_PHONE,
  CLEAR_PHONE,
  SET_GOOD_DEALS_CATEGORIES,
  SET_GOODDEALS_PHONE_ERROR,
  SET_WEB_SITE_URL_ERROR,
  SET_IMAGE_ERROR,
  SET_GOODDEALS_TITLE_FR,
  SET_GOODDEALS_DESCRIPTION_FR,
  SET_GOODDEALS_PROMOTION_FR,
  SET_TITLE_ERROR,
  SET_DESCRIPTION_ERROR,
  SET_GOODDEALS_DESCRIPTION_EN,
  SET_GOODDEALS_PROMOTION_EN,
  SET_GOODDEALS_TITLE_EN,
  SET_GOODDEALS_OPENING_TIME_FR,
  SET_GOODDEALS_OPENING_TIME_EN,
  SET_GOODDEALS_PRICE_FR,
  SET_GOODDEALS_PRICE_EN,
  SET_GOODDEALS_EMAIL_ERROR,
  CLEAR_EMAIL,
  SET_ADD_GOOD_DEAL_RESULT,
  SET_ADD_GOOD_DEAL_ERROR,
  FETCH_ADD_GOOD_DEAL,
  SET_OWN_GOOD_DEAL_RESULT,
  FETCH_GET_OWN_GOOD_DEALS,
  SET_GET_GOOD_DEAL_DETAILS_RESULT,
  FETCH_GET_GOOD_DEAL_DETAILS,
  SET_UPDATE_GOOD_DEAL_RESULT,
  SET_UPDATE_GOOD_DEAL_ERROR,
  SET_DELETE_GOOD_DEAL_RESULT,
  SET_DELETE_GOOD_DEAL_FAILURE,
  FETCH_DELETE_GOOD_DEAL,
  FETCH_UPDATE_GOOD_DEAL,
  RESET_GOODDEAL,
  SET_ADDRESS_USING_ZIPCODE_RESULT,
  SET_ADDRESS_USING_COORDINATES_RESULT,
  SET_CITY_USING_ZIPCODE_RESULT,
  SET_GOODDEAL_SELECTED_ADDRESS,
  SET_GOODDEAL_SELECTED_ZIPCODE,
  SET_GOODDEAL_SELECTED_CITY,
  SET_GOODDEAL_ADDRESS,
  SET_GOODDEAL_ZIP_CODE,
  SET_GOODDEAL_CITY,
  SET_GOODDEAL_EMAIL,
  SET_STRIPE_TOKEN,
  SET_GOODDEAL_SORTED,
  SET_PUBLISH_GOOD_DEAL_RESULT,
  SET_PUBLISH_GOOD_DEAL_FAILURE,
  FETCH_PUBLISH_GOOD_DEAL,
  FETCH_MODERATING_GOOD_DEAL_ACCEPT,
  FETCH_MODERATING_GOOD_DEAL_REFUSE,
  SET_MODERATING_GOOD_DEAL_ACCEPT_RESULT,
  SET_MODERATING_GOOD_DEAL_ACCEPT_FAILURE,
  SET_MODERATING_GOOD_DEAL_REFUSE_RESULT,
  SET_MODERATING_GOOD_DEAL_REFUSE_FAILURE,
  FETCH_GET_HOME_GOOD_DEALS,
  SET_HOME_GOODDEALS_RESULT,
  SET_GET_GOOD_DEAL_CHANNELS_RESULT,
  EDITING,
  PUBLISHED,
  SET_FB_URL,
  SET_FB_URL_ERROR,
  CLEAR_FB_URL,
  SET_ZIPCODE_ERROR,
  SET_CITY_ERROR,
  SET_CITIES,
  ADD_GOODDEAL_CITY,
  DELETE_GOODDEAL_CITY,
  MISSING_CITY,
  ADD_GOODDEAL_CHANNEL,
  DELETE_GOODDEAL_CHANNEL,
  MISSING_CHANNEL,
  SET_GOODDEALS_TEMPLATE,
  SET_GOODDEALS_CHOICES_TAGS,
  SELECT_GOODDEALS_ACTIVITY_CHOICE_FR,
  SET_GOODDEALS_ACTIVITY_DESCRIPTION_FR,
  SET_GOODDEALS_ACTIVITY_POST_DESCRIPTION_FR,
  SET_GOODDEALS_ACTIVITY_DESCRIPTION_ERROR,
  GOODDEALS_STEP2_VALIDATED,
  SET_GOODDEALS_ACTIVITY_POST_DESCRIPTION_EN,
  SET_GOODDEALS_ACTIVITY_NAME_EN,
  SET_GOODDEALS_ACTIVITY_DESCRIPTION_EN,
  SELECT_GOODDEALS_ACTIVITY_CHOICE_EN,
  SET_GOODDEALS_CUSTOM_URI,
  SET_CUSTOM_URI_ERROR,
  SET_CHANNELS
} from "actions/types";
import { t } from "../services/i18n";
import _ from "lodash";

const OTHER = "Autre ...";

const initialState = {
  _id: null,
  campsites: [],
  communitiesIds: [],
  goodDeals: {},
  customUri: "",
  customUriErrorMsg: null,
  loc: null,
  step1ErrorMsg: null,
  isFetching: false,
  isFetchingList: false,
  imageErrorMsg: null,
  categoryId: null,
  addresses: {},
  customAddress: null,
  address: null,
  selectedAddress: OTHER,
  addressUpdated: false,
  zipCode: null,
  zipCodes: {},
  customZipCode: null,
  selectedZipCode: OTHER,
  zipCodeUpdated: false,
  customCity: null,
  city: null,
  selectedCity: OTHER,
  cities: {},
  phone: null,
  email: null,
  emailState: null,
  emailErrorMsg: null,
  openingTime: null,
  price: null,
  phoneErrorMsg: null,
  phoneState: null,
  webSiteUrl: null,
  webSiteUrlErrorMsg: null,
  webSiteUrlState: null,
  fbUrl: null,
  fbUrlErrorMsg: null,
  fbUrlState: null,
  title: null,
  titleErrorMsg: null,
  promotion: null,
  description: null,
  descriptionErrorMsg: null,
  lang: null,
  files: [],
  snackErrorMsg: null,
  snackInfoMsg: null,
  newWizard: null,
  photoUri: null,
  token: null,
  page: 1,
  limit: 3,
  hasNextPage: false,
  nextPage: 2,
  totalPages: 0,
  totalDocs: 0,
  sorted: [{ id: "dateUpdated", desc: true }],
  suggestions: [],
  campsitesEntities: [],
  status: EDITING,
  zipCodeErrorMsg: null,
  cityErrorMsg: null,
  publishedCities: [],
  citiesIds: [],
  citiesEntities: [],
  publishedCityErrorMsg: null,
  channels: {},
  publishedChannelErrorMsg: null,
  templateId: "",
  choices: null,
  choicesTags: [],
  choiceId: null,
  activityName: null,
  activityDescriptionErrorMsg: null,
  activityDescription: null,
  activityPostDescription: null,
  activityNameEn: null,
  activityDescriptionEn: null,
  activityPostDescriptionEn: null
};

// verifies if value is a valid URL
const verifyUrl = value => {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
};

const verifyPhone = phone => {
  const phoneRegex = /^[+][0-9]{1,4}[" "][(][0][)][1-9][" "][0-9]{2}[" "][0-9]{2}[" "][0-9]{2}[" "][0-9]{2}$/g;
  return phoneRegex.test(phone);
};

const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRex.test(value);
};

const replaceChar = char => {
  // good nothing to change
  if (RegExp("[a-z0-9-]{1}").test(char)) {
    return char;
  }
  // Uppercase to lower case
  if (RegExp("[A-Z]{1}").test(char)) {
    return char.toLowerCase();
  }
  if (RegExp("[àâÀÂ]{1}").test(char)) {
    return "a";
  }
  if (RegExp("[éèêëÉÈÊË]{1}").test(char)) {
    return "e";
  }
  if (RegExp("[îïÎÏ]{1}").test(char)) {
    return "i";
  }
  if (RegExp("[ôöÔÖ]{1}").test(char)) {
    return "o";
  }
  if (RegExp("[ûùüÛÙÜ]{1}").test(char)) {
    return "u";
  }
  if (RegExp("[ç]{1}").test(char)) {
    return "c";
  }
  return "-";
};

function keepState(state) {
  const suggestions = [...state.suggestions];
  // eslint-disable-next-line
  for (const suggestion of suggestions) {
    suggestion.showInfo = true;
  }
  return {
    ...initialState,
    campsitesEntities: state.campsitesEntities,
    citiesEntities: state.citiesEntities,
    suggestions,
    goodDeals: state.goodDeals,
    page: state.page,
    limit: state.limit,
    hasNextPage: state.hasNextPage,
    totalPages: state.totalPages,
    totalDocs: state.totalDocs
  };
}

// TODO Remove communitiesIds
export default function gooddealReducer(
  state = { ...initialState },
  action = {}
) {
  switch (action.type) {
    case API_START:
      if (
        action.payload === FETCH_GET_OWN_GOOD_DEALS ||
        action.payload === FETCH_GET_HOME_GOOD_DEALS
      ) {
        return {
          ...state,
          isFetchingList: true,
          goodDeals: {}
        };
      }
      if (
        action.payload === FETCH_GET_GOOD_DEAL_DETAILS ||
        action.payload === FETCH_ADD_GOOD_DEAL ||
        action.payload === FETCH_UPDATE_GOOD_DEAL ||
        action.payload === FETCH_DELETE_GOOD_DEAL ||
        action.payload === FETCH_PUBLISH_GOOD_DEAL ||
        action.payload === FETCH_MODERATING_GOOD_DEAL_ACCEPT ||
        action.payload === FETCH_MODERATING_GOOD_DEAL_REFUSE
      ) {
        return {
          ...state,
          isFetching: true,
          snackErrorMsg: null,
          snackInfoMsg: null,
          newWizard: null
        };
      }
      break;

    case SET_GOODDEALS_CUSTOM_URI:
      if (
        !action.value ||
        RegExp("[a-z0-9-]{1}").test(action.value[action.value.length - 1])
      ) {
        return {
          ...state,
          customUri: action.value,
          customUriErrorMsg: null
        };
      }
      break;

    case SET_CUSTOM_URI_ERROR:
      return {
        ...state,
        customUriErrorMsg: t("city:urimandatory")
      };

    case SET_GOODDEALS_ACTIVITY_DESCRIPTION_FR: {
      if (state.choiceId) {
        const choices = { ...state.choices };
        choices[state.choiceId].description = action.payload;
        return {
          ...state,
          choices,
          activityDescription: action.payload,
          activityDescriptionErrorMsg: null
        };
      }
      return {
        ...state,
        activityDescription: action.payload,
        activityDescriptionErrorMsg: null
      };
    }

    case SET_GOODDEALS_ACTIVITY_POST_DESCRIPTION_FR: {
      if (state.choiceId) {
        const choices = { ...state.choices };
        choices[state.choiceId].postDescription = action.payload;
        return {
          ...state,
          choices,
          activityPostDescription: action.payload
        };
      }
      return {
        ...state,
        activityPostDescription: action.payload
      };
    }

    case SET_GOODDEALS_ACTIVITY_DESCRIPTION_ERROR:
      if (action.choiceId) {
        return {
          ...state,
          activityDescriptionErrorMsg: t("gooddeal:descriptionmandatory"),
          choiceId: action.choiceId,
          activityDescription: state.choices[action.choiceId].description,
          activityPostDescription:
            state.choices[action.choiceId].postDescription
        };
      } else {
        return {
          ...state,
          activityDescriptionErrorMsg: t("gooddeal:descriptionmandatory")
        };
      }

    case SET_GOODDEALS_ACTIVITY_DESCRIPTION_EN: {
      let en =
        state.choiceId &&
        state.choices[state.choiceId] &&
        state.choices[state.choiceId].lang
          ? state.choices[state.choiceId].lang.en
          : {};
      if (action.payload) {
        en = {
          en: {
            ...en,
            description: action.payload
          }
        };
      }
      if (state.choiceId) {
        const choices = { ...state.choices };
        choices[state.choiceId].lang = en;
        return { ...state, choices, activityDescriptionEn: action.payload };
      }
      return { ...state, activityDescriptionEn: action.payload };
    }

    case SET_GOODDEALS_ACTIVITY_POST_DESCRIPTION_EN: {
      let en =
        state.choiceId &&
        state.choices[state.choiceId] &&
        state.choices[state.choiceId].lang
          ? state.choices[state.choiceId].lang.en
          : {};
      if (action.payload) {
        en = {
          en: {
            ...en,
            postDescription: action.payload
          }
        };
      }
      if (state.choiceId) {
        const choices = { ...state.choices };
        choices[state.choiceId].lang = en;
        return { ...state, choices, activityPostDescriptionEn: action.payload };
      }
      return { ...state, activityPostDescriptionEn: action.payload };
    }

    case SET_GOODDEALS_ACTIVITY_NAME_EN: {
      let en =
        state.choiceId &&
        state.choices[state.choiceId] &&
        state.choices[state.choiceId].lang
          ? state.choices[state.choiceId].lang.en
          : {};
      if (action.payload) {
        en = {
          en: {
            ...en,
            name: action.payload
          }
        };
      }
      if (state.choiceId) {
        const choices = { ...state.choices };
        choices[state.choiceId].lang = en;
        return { ...state, choices, activityNameEn: action.payload };
      }
      return { ...state, activityNameEn: action.payload };
    }

    case GOODDEALS_STEP2_VALIDATED: {
      let name,
        description,
        postDescription = null;
      if (
        state.choices &&
        state.choiceId &&
        state.choices[state.choiceId] &&
        state.choices[state.choiceId].lang &&
        state.choices[state.choiceId].lang.en
      ) {
        name = state.choices[state.choiceId].lang.en.name;
        description = state.choices[state.choiceId].lang.en.description;
        postDescription = state.choices[state.choiceId].lang.en.postDescription;
      } else if (state.lang && state.lang.en) {
        name = state.lang.en.name;
        description = state.lang.en.description;
        postDescription = state.lang.en.postDescription;
      }
      return {
        ...state,
        activityNameEn: name,
        activityDescriptionEn: description,
        activityPostDescriptionEn: postDescription
      };
    }

    case SET_GOODDEALS_CHOICES_TAGS: {
      if (state.choicesTags.length < action.payload.length) {
        const value = action.payload[action.payload.length - 1];
        // We add a choice only if not already present
        if (state.choicesTags.indexOf(value) === -1) {
          const name = state.activityName ? state.activityName : state.title;
          let choices = {
            ...(state.choices || {
              [name]: {
                name,
                description: state.activityDescription,
                postDescription: state.activityPostDescription
              }
            }),
            [value]: {
              name: value
            }
          };
          return {
            ...state,
            choicesTags: state.choices
              ? action.payload
              : [name, ...action.payload],
            choices: choices,
            choiceId: value,
            activityDescription: null,
            activityPostDescription: null,
            activityDescriptionErrorMsg: null
          };
        }
      } else {
        // We remove a choice
        // Array.prototype.diff = function(arr2) {
        //   return this.filter(x => !arr2.includes(x));
        // };

        const removedChoice = _.difference(state.choicesTags, action.payload);
        const obj = Object.values(state.choices).find(
          obj => obj.name === removedChoice[0]
        );
        let { [obj.name]: ignored, ...choices } = state.choices;
        const choicesValues = Object.values(choices);
        if (choicesValues.length <= 1) {
          const { name, description, postDescription, lang } =
            choicesValues.length === 0 ? ignored : choicesValues[0];
          return {
            ...state,
            choicesTags: [],
            choices: null,
            choiceId: null,
            lang,
            activityName: name,
            activityDescription: description,
            activityPostDescription: postDescription,
            activityDescriptionErrorMsg: null
          };
        }
        return {
          ...state,
          choicesTags: action.payload,
          choices,
          choiceId:
            state.choiceId === removedChoice[0]
              ? action.payload[0]
              : state.choiceId,
          activityDescription:
            state.choiceId === removedChoice[0]
              ? choices[action.payload[0]].description
              : state.activityDescription,
          activityPostDescription:
            state.choiceId === removedChoice[0]
              ? choices[action.payload[0]].postDescription
              : state.activityPostDescription,
          activityDescriptionErrorMsg: null
        };
      }
      break;
    }

    case SELECT_GOODDEALS_ACTIVITY_CHOICE_FR: {
      const { description, postDescription } = state.choices[action.payload];
      return {
        ...state,
        choiceId: action.payload,
        activityDescription: description,
        activityPostDescription: postDescription,
        activityDescriptionErrorMsg: null
      };
    }

    case SELECT_GOODDEALS_ACTIVITY_CHOICE_EN: {
      let name, description, postDescription;
      if (
        state.choices[action.payload] &&
        state.choices[action.payload].lang &&
        state.choices[action.payload].lang.en
      ) {
        name = state.choices[action.payload].lang.en.name;
        description = state.choices[action.payload].lang.en.description;
        postDescription = state.choices[action.payload].lang.en.postDescription;
      }
      return {
        ...state,
        choiceId: action.payload,
        activityNameEn: name,
        activityDescriptionEn: description,
        activityPostDescriptionEn: postDescription
      };
    }

    case ADD_GOODDEAL_CHANNEL: {
      const channels = { ...state.channels };
      channels[action.value._id].selected = true;

      return {
        ...state,
        channels
      };
    }

    case DELETE_GOODDEAL_CHANNEL: {
      const channels = { ...state.channels };
      Object.values(channels).filter(obj => obj.selected)[
        action.index
      ].selected = false;
      return {
        ...state,
        channels
      };
    }

    case SET_PUBLISH_GOOD_DEAL_RESULT:
      return {
        ...keepState(state),
        snackInfoMsg:
          action.payload.status === PUBLISHED
            ? t("gooddeal:publishsuccess")
            : t("gooddeal:moderatesuccess")
      };

    case SET_MODERATING_GOOD_DEAL_ACCEPT_RESULT:
      return {
        ...keepState(state),
        snackInfoMsg: t("gooddeal:acceptsuccess")
      };

    case SET_MODERATING_GOOD_DEAL_REFUSE_RESULT:
      return {
        ...keepState(state),
        snackInfoMsg: t("gooddeal:refusesuccess")
      };

    case SET_PUBLISH_GOOD_DEAL_FAILURE:
      return {
        ...state,
        snackErrorMsg: t("gooddeal:publisherror")
      };

    case SET_MODERATING_GOOD_DEAL_ACCEPT_FAILURE:
      return {
        ...state,
        snackErrorMsg: t("gooddeal:accepterror")
      };

    case SET_MODERATING_GOOD_DEAL_REFUSE_FAILURE:
      return {
        ...state,
        snackErrorMsg: t("gooddeal:refuseerror")
      };

    case SET_ADDRESS_USING_ZIPCODE_RESULT:
    case SET_ADDRESS_USING_COORDINATES_RESULT: {
      const gouvData = action.payload;
      if (gouvData && gouvData.features && gouvData.features.length > 0) {
        // Sort data by distance
        const sortedData = gouvData.features.sort(function(obj1, obj2) {
          if (obj1.properties && obj2.properties) {
            return obj1.properties.distance - obj2.properties.distance;
          } else {
            return 0;
          }
        });
        const addresses = {};
        const zipCodes = {};
        const cities = {};
        let address = null;
        // eslint-disable-next-line
        for (const val of sortedData) {
          if (
            val.properties !== null &&
            val.properties.postcode !== null &&
            val.properties.name !== null &&
            val.properties.city !== null
          ) {
            if (!address) {
              address = val.properties.name;
            }
            addresses[val.properties.name] = {
              city: val.properties.city,
              zipCode: val.properties.postcode,
              loc: val.geometry
            };
            zipCodes[val.properties.postcode] = {
              city: val.properties.city,
              loc: val.geometry
            };
            cities[val.properties.city] = {
              loc: val.geometry
            };
          }
        }
        if (Object.keys(addresses).length > 0) {
          return {
            ...state,
            addressUpdated: false,
            addresses,
            selectedAddress: address,
            address,
            zipCodes,
            selectedZipCode: addresses[address].zipCode,
            zipCode: addresses[address].zipCode,
            cities,
            selectedCity: addresses[address].city,
            city: addresses[address].city,
            loc: addresses[address].loc
          };
        }
      }
      break;
    }

    case SET_CITY_USING_ZIPCODE_RESULT: {
      const gouvData = action.payload;
      if (gouvData && gouvData.features && gouvData.features.length > 0) {
        const zipCodes = {};
        const cities = {};
        let zipCode = null;
        // eslint-disable-next-line
        for (const val of gouvData.features) {
          if (
            val.properties !== null &&
            val.properties.postcode === state.zipCode &&
            val.geometry !== null &&
            val.geometry.coordinates !== null &&
            val.geometry.coordinates.length === 2 &&
            !cities[val.properties.city]
          ) {
            zipCodes[val.properties.postcode] = {
              city: val.properties.city,
              loc: val.geometry
            };
            if (!zipCode) {
              zipCode = val.properties.postcode;
            }
            cities[val.properties.city] = {
              loc: val.geometry
            };
          }
        }
        if (Object.keys(cities).length > 0) {
          return {
            ...state,
            zipCodeUpdated: false,
            zipCodes,
            selectedZipCode: zipCode,
            zipCode,
            cities,
            selectedCity: zipCodes[zipCode].city,
            city: zipCodes[zipCode].city,
            loc: zipCodes[zipCode].loc,
            cityErrorMsg: null
          };
        }
      }
      return {
        ...state,
        zipCodeUpdated: false
      };
    }

    case SET_GOODDEAL_SELECTED_ADDRESS: {
      if (action.payload === OTHER) {
        return {
          ...state,
          selectedAddress: action.payload,
          address: state.customAddress
        };
      } else {
        const address = state.addresses[action.payload];
        return {
          ...state,
          selectedZipCode: address.zipCode,
          zipCode: address.zipCode,
          city: address.city,
          selectedCity: address.city,
          selectedAddress: action.payload,
          address: action.payload,
          loc: address.loc
        };
      }
    }

    case SET_GOODDEAL_SELECTED_ZIPCODE: {
      if (action.payload === OTHER) {
        return {
          ...state,
          selectedZipCode: action.payload,
          zipCode: state.customZipCode,
          zipCodeErrorMsg: null
        };
      } else {
        const zipCode = state.zipCodes[action.payload];
        return {
          ...state,
          selectedZipCode: action.payload,
          zipCode: action.payload,
          city: zipCode.city,
          selectedCity: zipCode.city,
          loc: zipCode.loc,
          zipCodeErrorMsg: null,
          cityErrorMsg: null
        };
      }
    }

    case SET_GOODDEAL_SELECTED_CITY:
      if (action.payload === OTHER) {
        return {
          ...state,
          selectedCity: action.payload,
          city: state.customCity,
          cityErrorMsg: null
        };
      } else {
        return {
          ...state,
          selectedCity: action.payload,
          city: action.payload,
          loc: state.cities[action.payload].loc,
          cityErrorMsg: null
        };
      }

    case SET_GOODDEAL_ADDRESS:
      return {
        ...state,
        customAddress: action.value,
        address: action.value,
        addressUpdated: true
      };

    case SET_GOODDEAL_ZIP_CODE:
      return {
        ...state,
        customZipCode: action.value,
        zipCode: action.value,
        zipCodeUpdated: true,
        zipCodeErrorMsg: null
      };

    case SET_GOODDEAL_CITY:
      return {
        ...state,
        customCity: action.value,
        city: action.value,
        cityErrorMsg: null
      };

    case SET_GOODDEALS_OPENING_TIME_FR:
      return {
        ...state,
        openingTime: action.value
      };

    case SET_GOODDEALS_PRICE_FR:
      return {
        ...state,
        price: action.value
      };

    case SET_GOODDEALS_OPENING_TIME_EN: {
      let en = state.lang
        ? { ...state.lang.en, openingTime: action.value }
        : { openingTime: action.value };

      return {
        ...state,
        lang: { en }
      };
    }

    case SET_GOODDEALS_PRICE_EN: {
      let en = state.lang
        ? { ...state.lang.en, price: action.value }
        : { price: action.value };

      return {
        ...state,
        lang: { en }
      };
    }

    case SET_HOME_GOODDEALS_RESULT:
    case SET_OWN_GOOD_DEAL_RESULT: {
      const { goodDeals, infospages } = action.entities;
      const { page, hasNextPage, totalPages, totalDocs, limit } = infospages[
        "1"
      ];
      return {
        ...state,
        goodDeals: goodDeals ? goodDeals : {},
        page,
        hasNextPage,
        totalPages,
        totalDocs,
        limit
      };
    }

    case SET_GOODDEAL_SORTED: {
      return {
        ...state,
        sorted: action.value
      };
    }

    case ADD_GOODDEAL_CAMPSITE: {
      const suggestions = [
        ...state.suggestions.filter(obj => obj.id !== action.value.id)
      ];
      const campsite = {
        ...state.suggestions.filter(obj => obj.id === action.value.id)[0]
      };
      campsite.showInfo = false;
      suggestions.push(campsite);
      if (
        action.value.position &&
        !state.communitiesIds.includes(action.value.id)
      ) {
        return {
          ...state,
          campsites: [...state.campsites, action.value],
          communitiesIds: [...state.communitiesIds, action.value.id],
          suggestions,
          step1ErrorMsg: null
        };
      } else {
        return {
          ...state,
          suggestions,
          step1ErrorMsg: null
        };
      }
    }

    case ADD_GOODDEAL_CITY: {
      const suggestions = [
        ...state.suggestions.filter(obj => obj.id !== action.value.id)
      ];
      const city = {
        ...state.suggestions.filter(obj => obj.id === action.value.id)[0]
      };
      city.showInfo = false;
      suggestions.push(city);
      if (action.value.position && !state.citiesIds.includes(action.value.id)) {
        return {
          ...state,
          publishedCities: [...state.publishedCities, action.value],
          citiesIds: [...state.citiesIds, action.value.id],
          suggestions,
          publishedCityErrorMsg: null
        };
      } else {
        return {
          ...state,
          suggestions,
          publishedCityErrorMsg: null
        };
      }
    }

    case DELETE_GOODDEAL_CAMPSITE: {
      const suggestions = [
        ...state.suggestions.filter(
          obj => obj.id !== state.communitiesIds[action.value]
        )
      ];
      const campsite = { ...state.campsites[action.value] };
      campsite.showInfo = true;
      suggestions.push(campsite);
      return {
        ...state,
        campsites: state.campsites.filter(
          (campsite, index) => index !== action.value
        ),
        communitiesIds: state.communitiesIds.filter(
          (campsite, index) => index !== action.value
        ),
        step1ErrorMsg: null,
        suggestions
      };
    }

    case DELETE_GOODDEAL_CITY: {
      const suggestions = [
        ...state.suggestions.filter(
          obj => obj.id !== state.citiesIds[action.value]
        )
      ];
      const city = { ...state.publishedCities[action.value] };
      city.showInfo = true;
      suggestions.push(city);
      return {
        ...state,
        publishedCities: state.publishedCities.filter(
          (city, index) => index !== action.value
        ),
        citiesIds: state.citiesIds.filter(
          (city, index) => index !== action.value
        ),
        publishedCityErrorMsg: null,
        suggestions
      };
    }

    case MISSING_CAMPSITE:
      return {
        ...state,
        step1ErrorMsg: "Veuillez selectionner au minimum un camping"
      };

    case MISSING_CITY:
      return {
        ...state,
        publishedCityErrorMsg: t("event:missingcity")
      };

    case MISSING_CHANNEL:
      return {
        ...state,
        publishedChannelErrorMsg: t("event:missingchannel")
      };

    // case SET_CAMPSITES: {
    //   return {
    //     ...state,
    //     suggestions: Object.values(action.entities.campsites).map(obj => ({
    //       position: { lat: obj.latitude, lng: obj.longitude },
    //       showInfo: true,
    //       name: obj.name,
    //       id: obj._id,
    //       icon: obj.subscribe
    //         ? "https://mt.google.com/vt/icon/name=icons/spotlight/camping_v_L_8x.png&scale=1.2"
    //         : "https://mt.google.com/vt/icon/name=icons/spotlight/camping_search_v_L_8x.png&scale=1.2"
    //     })),
    //     campsitesEntities: action.entities.campsites
    //   };
    // }

    case SET_CITIES: {
      return {
        ...state,
        suggestions: Object.values(action.entities.cities).map(obj => ({
          position: {
            lng: obj.loc?.coordinates[0],
            lat: obj.loc?.coordinates[1]
          },
          showInfo: true,
          name: obj.name,
          id: obj._id,
          icon: obj.subscribe
            ? "https://mt.google.com/vt/icon/name=icons/spotlight/civic_building_v_L_8x.png&scale=1.2"
            : "https://mt.google.com/vt/icon/name=icons/spotlight/civic_building_search_v_L_8x.png&scale=1.2"
        })),
        citiesEntities: action.entities.cities
      };
    }

    case SET_LOCALIZATION:
      return {
        ...state,
        loc: action.value
      };

    case SET_GOODDEALS_FILES_IMAGES:
      return {
        ...state,
        files: action.payload,
        imageErrorMsg: null,
        photoUri: null
      };

    case REMOVE_GOODDEALS_IMAGE:
      return {
        ...state,
        files: [],
        photoUri: null,
        imageErrorMsg: null
      };

    case SET_IMAGE_ERROR:
      return {
        ...state,
        imageErrorMsg: t("gooddeal:imagemandatory")
      };

    case SET_WEB_SITE_URL:
      return {
        ...state,
        webSiteUrl: action.value,
        webSiteUrlErrorMsg: null,
        webSiteUrlState: verifyUrl(action.value) ? "success" : "error"
      };

    case SET_WEB_SITE_URL_ERROR:
      return {
        ...state,
        webSiteUrlErrorMsg: t("gooddeal:invalidurl")
      };

    case SET_ZIPCODE_ERROR:
      return {
        ...state,
        zipCodeErrorMsg: t("profile:zipcodemandatory")
      };

    case SET_CITY_ERROR:
      return {
        ...state,
        cityErrorMsg: t("profile:citymandatory")
      };

    case CLEAR_WEB_SITE_URL:
      return {
        ...state,
        webSiteUrl: null,
        webSiteUrlState: null,
        webSiteUrlErrorMsg: null
      };

    case SET_FB_URL:
      return {
        ...state,
        fbUrl: action.value,
        fbUrlErrorMsg: null,
        fbUrlState: verifyUrl(action.value) ? "success" : "error"
      };

    case SET_FB_URL_ERROR:
      return {
        ...state,
        fbUrlErrorMsg: t("gooddeal:invalidurl")
      };

    case CLEAR_FB_URL:
      return {
        ...state,
        fbUrl: null,
        fbUrlState: null,
        fbUrlErrorMsg: null
      };

    case SET_GOOD_DEALS_CATEGORIES:
      if (!state.categoryId) {
        return {
          ...state,
          categoryId: Object.keys(action.entities.goodDealsCategories)[0]
        };
      }
      break;

    case SET_GOODDEALS_CATEGORY:
      return {
        ...state,
        categoryId: action.category.value
      };

    case SET_GOODDEALS_TEMPLATE: {
      let choices = null;
      let choicesTags = [];
      let choiceId = null;
      if (action.template && action.template.choices) {
        choices = {};
        /*eslint-disable */
        const re = new RegExp("\\${name}", "gi");
        /*eslint-enable */
        for (const key of Object.keys(action.template.choices)) {
          choices[key] = {
            name: action.template.choices[key].name,
            description:
              action.template.choices[key].gooddealDescription &&
              action.template.choices[key].gooddealDescription.replace(
                re,
                state.title
              ),
            postDescription:
              action.template.choices[key].gooddealPostDescription &&
              action.template.choices[key].gooddealPostDescription.replace(
                re,
                state.title
              ),
            lang: {
              en: {
                name:
                  action.template.choices[key].lang &&
                  action.template.choices[key].lang.en &&
                  action.template.choices[key].lang.en.name,
                description:
                  action.template.choices[key].lang &&
                  action.template.choices[key].lang.en &&
                  action.template.choices[key].lang.en.gooddealDescription &&
                  action.template.choices[
                    key
                  ].lang.en.gooddealDescription.replace(re, state.title),
                postDescription:
                  action.template.choices[key].lang &&
                  action.template.choices[key].lang.en &&
                  action.template.choices[key].lang.en
                    .gooddealPostDescription &&
                  action.template.choices[
                    key
                  ].lang.en.gooddealPostDescription.replace(re, state.title)
              }
            }
          };
        }

        const choicesValues = Object.values(choices);
        choicesTags = choicesValues.map(choice => choice.name);
        choiceId = choicesTags[0];
      }

      return {
        ...state,
        choices,
        choicesTags,
        choiceId,
        activityDescription: choiceId ? choices[choiceId].description : null,
        activityPostDescription: choiceId
          ? choices[choiceId].postDescription
          : null,
        templateId: action.template._id
      };
    }

    case SET_GOODDEALS_PHONE:
      return {
        ...state,
        phone: action.value,
        phoneErrorMsg: null,
        phoneState: verifyPhone(action.value) ? "success" : "error"
      };

    case SET_GOODDEAL_EMAIL:
      return {
        ...state,
        email: action.value,
        emailErrorMsg: null,
        emailState: verifyEmail(action.value) ? "success" : "error"
      };

    case SET_GOODDEALS_PHONE_ERROR:
      return {
        ...state,
        phoneErrorMsg: t("profile:invalidphone")
      };

    case SET_GOODDEALS_EMAIL_ERROR:
      return {
        ...state,
        emailErrorMsg: t("gooddeal:invalidemail")
      };

    case SET_GOODDEALS_TITLE_FR:
      if (state.newWizard) {
        return {
          ...state,
          title: action.value,
          titleErrorMsg: null
        };
      } else {
        let customUri = "";
        if (action.value) {
          for (const c of action.value) {
            customUri += replaceChar(c);
          }
          // customUri =
          //   state.customUri +
          //   replaceChar(action.value[action.value.length - 1]);
        }
        return {
          ...state,
          customUri,
          customUriErrorMsg: null,
          title: action.value,
          titleErrorMsg: null
        };
      }

    case SET_GOODDEALS_TITLE_EN: {
      let en = state.lang
        ? { ...state.lang.en, title: action.value }
        : { title: action.value };

      return {
        ...state,
        lang: { en }
      };
    }

    case SET_TITLE_ERROR:
      return {
        ...state,
        titleErrorMsg: t("gooddeal:titlemandatory")
      };

    case SET_GOODDEALS_PROMOTION_FR:
      return {
        ...state,
        promotion: action.value
      };

    case SET_GOODDEALS_PROMOTION_EN: {
      let en = state.lang
        ? { ...state.lang.en, promotion: action.value }
        : { promotion: action.value };

      return {
        ...state,
        lang: { en }
      };
    }

    case SET_STRIPE_TOKEN:
      return {
        ...state,
        token: action.payload
      };

    case SET_GOODDEALS_DESCRIPTION_FR:
      return {
        ...state,
        description: action.value,
        descriptionErrorMsg: null
      };

    case SET_GOODDEALS_DESCRIPTION_EN: {
      let en = state.lang
        ? { ...state.lang.en, description: action.value }
        : { description: action.value };

      return {
        ...state,
        lang: { en }
      };
    }

    case SET_DESCRIPTION_ERROR:
      return {
        ...state,
        descriptionErrorMsg: t("gooddeal:descriptionmandatory")
      };

    case CLEAR_PHONE:
      return {
        ...state,
        phone: null,
        phoneState: null,
        phoneErrorMsg: null
      };

    case CLEAR_EMAIL:
      return {
        ...state,
        email: null,
        emailState: null,
        emailErrorMsg: null
      };

    case SET_ADD_GOOD_DEAL_RESULT:
      return {
        ...keepState(state),
        snackInfoMsg: t("gooddeal:addsuccess")
      };

    case SET_UPDATE_GOOD_DEAL_RESULT:
      return {
        ...keepState(state),
        snackInfoMsg: t("gooddeal:updatesuccess")
      };

    case SET_DELETE_GOOD_DEAL_RESULT:
      return {
        ...state,
        snackInfoMsg: t("gooddeal:deletesuccess")
      };

    case SET_ADD_GOOD_DEAL_ERROR:
      return {
        ...state,
        snackErrorMsg: t("gooddeal:adderror")
      };

    case SET_DELETE_GOOD_DEAL_FAILURE:
      return {
        ...state,
        snackErrorMsg: t("gooddeal:deleteerror")
      };

    case SET_UPDATE_GOOD_DEAL_ERROR:
      return {
        ...state,
        snackErrorMsg: t("gooddeal:updateerror")
      };

    case SET_GET_GOOD_DEAL_DETAILS_RESULT: {
      const {
        address,
        zipCode,
        city,
        choices,
        activityDescription,
        activityPostDescription,
        ...data
      } = action.payload;

      let choicesTags = [];
      let choiceId = null;
      if (choices) {
        const choicesValues = Object.values(choices);
        choicesTags = choicesValues.map(choice => choice.name);
        if (!choicesTags[0]) {
          // TO REMOVE after migration
          // Missing name => migration procedure : add name to each items
          choicesTags = Object.keys(choices);
          for (let index = 0; index < choicesValues.length; index++) {
            choicesValues[index].name = choicesTags[index];
          }
        }
        choiceId = choicesTags[0];
      }

      return {
        ...state,
        ...data,
        activityDescription: choiceId
          ? choices[choiceId].description
          : activityDescription,
        activityPostDescription: choiceId
          ? choices[choiceId].postDescription
          : activityPostDescription,
        choices,
        choiceId,
        choicesTags,
        address,
        customAddress: address,
        zipCode,
        customZipCode: zipCode,
        city,
        customCity: city,
        newWizard: "update"
      };
    }

    case SET_GET_GOOD_DEAL_CHANNELS_RESULT: {
      const { ad, channels = {} } = action.entities;

      const { _id, status } = Object.values(ad)[0];

      Object.values(channels).map(obj => {
        obj.selected = true;
        let suffix;
        switch (obj.channeltype) {
          case "CityGooddealChannel":
            suffix = " - Villes [HapiCity]";
            break;
          case "CampsiteGooddealChannel":
            suffix = " - Campings [O’Camping]";
            break;
          case "NeighborhoodGooddealChannel":
            suffix = " - Voisins [HapiColibri]";
            break;
          default:
            suffix = "";
        }
        obj.name += suffix;
        return obj;
      });

      return { ...state, _id, status, newWizard: "publish", channels };
    }

    case SET_CHANNELS:
      if (action.entities.channels) {
        for (const channel of Object.values(action.entities.channels)) {
          switch (channel.channeltype) {
            case "CityGooddealChannel":
              channel.name += " - Villes [HapiCity]";
              break;
            case "CampsiteGooddealChannel":
              channel.name += " - Campings [O’Camping]";
              break;
            case "NeighborhoodGooddealChannel":
              channel.name += " - Voisins [HapiColibri]";
              break;
            default:
              // Do nothing
              break;
          }
        }
        return {
          ...state,
          channels: {
            ...action.entities.channels,
            ...state.channels
          }
        };
      }
      break;

    case RESET_GOODDEAL:
      return {
        ...keepState(state)
      };

    case API_END:
      if (
        action.payload === FETCH_GET_GOOD_DEAL_DETAILS ||
        action.payload === FETCH_ADD_GOOD_DEAL ||
        action.payload === FETCH_UPDATE_GOOD_DEAL ||
        action.payload === FETCH_DELETE_GOOD_DEAL ||
        action.payload === FETCH_PUBLISH_GOOD_DEAL ||
        action.payload === FETCH_MODERATING_GOOD_DEAL_ACCEPT ||
        action.payload === FETCH_MODERATING_GOOD_DEAL_REFUSE
      ) {
        return {
          ...state,
          isFetching: false
        };
      }
      if (
        action.payload === FETCH_GET_OWN_GOOD_DEALS ||
        action.payload === FETCH_GET_HOME_GOOD_DEALS
      ) {
        return {
          ...state,
          isFetchingList: false
        };
      }
      break;

    default:
      return state;
  }
  return state;
}
