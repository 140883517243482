// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput";

import { SET_LOCATION_NAME_FR, SET_LOCATION_NAME_ERROR } from "actions/types";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  ...customInputStyle
};

class Step1 extends React.Component {
  sendState() {
    return this.state;
  }

  isValidated() {
    const { dispatch, name } = this.props;

    let success = true;

    if (!name) {
      dispatch({
        type: SET_LOCATION_NAME_ERROR
      });
      success = false;
    }

    return success;
  }

  render() {
    const { dispatch, nameErrorMsg, name } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Nom *"
            id="name-fr"
            formControlProps={{
              fullWidth: true
            }}
            error={nameErrorMsg !== null}
            helperText={nameErrorMsg}
            inputProps={{
              required: true,
              value: name ? name : "",
              onChange: e =>
                dispatch({
                  type: SET_LOCATION_NAME_FR,
                  payload: e.target.value
                })
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  const { name, nameErrorMsg } = state.locationReducer;

  return {
    name,
    nameErrorMsg
  };
};

export default connect(mapStateToProps)(withStyles(style)(Step1));
