import React from "react";
import canUseDOM from "can-use-dom";

import raf from "raf";
import GoogleMapComponent from "./GoogleMapComponent";

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;

const geolocation =
  canUseDOM && navigator.geolocation
    ? navigator.geolocation
    : {
        getCurrentPosition(success, failure) {
          failure("Your browser doesn't support geolocation.");
        }
      };

class GoogleMapHandlerComponent extends React.Component {
  state = {
    bounds: null,
    center: null,
    content: null,
    radius: 400,
    markers: [],
    latitude: 0,
    longitude: 0
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const derivedState = {};

  //   if (
  //     nextProps.location &&
  //     nextProps.location.markers !== prevState.markers
  //   ) {
  //     derivedState.markers = nextProps.location.markers;
  //   }
  //   if (
  //     nextProps.location &&
  //     nextProps.location.center &&
  //     nextProps.location.center !== prevState.center
  //   ) {
  //     derivedState.center = nextProps.location.center;
  //   }

  //   if (nextProps.latitude && nextProps.latitude !== prevState.latitude) {
  //     derivedState.latitude = nextProps.latitude;
  //   }
  //   if (nextProps.longitude && nextProps.longitude !== prevState.longitude) {
  //     derivedState.longitude = nextProps.longitude;
  //   }
  //   return Object.keys(derivedState).length === 0 ? null : derivedState;
  // }

  componentDidMount() {
    const tick = () => {
      if (this.isUnmounted) {
        return;
      }
      this.setState({ radius: Math.max(this.state.radius - 5, 0) });
      if (this.state.radius > 10) {
        raf(tick);
      }
    };

    geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude, onGetCurrentPosition } = this.props;

        if (this.isUnmounted) {
          return;
        }
        if (onGetCurrentPosition && !latitude && !longitude) {
          onGetCurrentPosition(
            position.coords.latitude,
            position.coords.longitude
          );
        }
        this.setState({
          center: {
            lat: latitude ? latitude : position.coords.latitude,
            lng: longitude ? longitude : position.coords.longitude
          },
          markers:
            this.state.markers.length > 0
              ? this.state.markers
              : [
                  {
                    position: {
                      lat: latitude ? latitude : position.coords.latitude,
                      lng: longitude ? longitude : position.coords.longitude
                    },
                    draggable: true
                  }
                ],
          content: "Location found using HTML5."
        });

        raf(tick);
      },
      reason => {
        const { latitude, longitude } = this.props;
        if (this.isUnmounted) {
          return;
        }
        this.setState({
          center: {
            lat: latitude ? latitude : 45.901136,
            lng: longitude ? longitude : 6.1169097
          },
          markers:
            this.state.markers.length > 0
              ? this.state.markers
              : [
                  {
                    position: {
                      lat: latitude ? latitude : 45.901136,
                      lng: longitude ? longitude : 6.1169097
                    },
                    draggable: true
                  }
                ],
          content: `Error: The Geolocation service failed (${reason}).`
        });
      }
    );

    if (document.getElementsByClassName("pac-container").length > 0) {
      const gmapSearchResultsBox = document.getElementsByClassName(
        "pac-container"
      )[0];
      gmapSearchResultsBox.style.zIndex = "2000";
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { longitude, latitude } = this.props;

    if (
      (longitude && longitude !== prevProps.longitude) ||
      (latitude && latitude !== prevProps.latitude)
    ) {
      this.setState({
        markers: [
          {
            position: {
              lat: latitude,
              lng: longitude
            },
            draggable: true
          }
        ],
        center: {
          lat: latitude,
          lng: longitude
        }
      });
    }

    if (document.getElementsByClassName("pac-container").length > 0) {
      const gmapSearchResultsBox = document.getElementsByClassName(
        "pac-container"
      )[0];
      gmapSearchResultsBox.style.zIndex = "2000";
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  isUnmounted = false;

  handleMapMounted = this.handleMapMounted.bind(this);
  handleBoundsChanged = this.handleBoundsChanged.bind(this);
  handleSearchBoxMounted = this.handleSearchBoxMounted.bind(this);
  handlePlacesChanged = this.handlePlacesChanged.bind(this);
  handleMarkerClose = this.handleMarkerClose.bind(this);
  handleMarkerClick = this.handleMarkerClick.bind(this);
  handleDragEnd = this.handleDragEnd.bind(this);

  handleMapMounted(map) {
    this._map = map;
  }

  handleBoundsChanged() {
    this.setState({
      bounds: this._map.getBounds(),
      center: this._map.getCenter()
    });
  }

  handleSearchBoxMounted(searchBox) {
    this._searchBox = searchBox;
  }

  handleLoadPlaces(markers) {}

  handlePlacesChanged() {
    const places = this._searchBox.getPlaces();

    // Add a marker for each place returned from search bar
    const markers = places.map(place => ({
      position: place.geometry.location,
      draggable: true
    }));

    // Set markers; set map center to first search result
    const mapCenter =
      markers.length > 0 ? markers[0].position : this.state.center;
    this.setState({
      center: mapCenter,
      markers
    });
    this.props.handleLocationChange(mapCenter, places);
  }

  // Toggle to 'true' to show InfoWindow and re-renders component
  handleMarkerClick(targetMarker) {
    this.setState(
      {
        markers: this.state.markers.map(marker => {
          if (marker === targetMarker) {
            if (marker.showInfo) {
              return {
                ...marker,
                showInfo: false
              };
            }
            return {
              ...marker,
              showInfo: true
            };
          }
          return marker;
        })
      },
      () => {
        const { onMarkerClick } = this.props;
        if (onMarkerClick) {
          onMarkerClick(targetMarker);
        }
      }
    );
  }

  handleMarkerClose(targetMarker) {
    this.setState({
      markers: this.state.markers.map(marker => {
        if (marker === targetMarker) {
          return {
            ...marker,
            showInfo: false
          };
        }
        return marker;
      })
    });
  }

  handleDragEnd(coord, targetMarker) {
    const { latLng } = coord;
    const { onMarkerChange } = this.props;

    if (onMarkerChange) {
      onMarkerChange(latLng.lat(), latLng.lng());
    }

    this.setState({
      markers: this.state.markers.map(marker => {
        if (marker === targetMarker) {
          return {
            ...marker,
            position: {
              lat: latLng.lat(),
              lng: latLng.lng()
            }
          };
        }
        return marker;
      })
    });
  }

  render() {
    const {
      classes,
      dispatch,
      zoom,
      addProp,
      withSearchBox = false
    } = this.props;
    const { center, bounds, markers } = this.state;
    return (
      center && (
        <GoogleMapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAP_API_KEY}${
            withSearchBox ? "&libraries=places" : ""
          }`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: "100%" }} />}
          mapElement={<div style={{ height: "100%" }} />}
          center={center}
          onMapMounted={this.handleMapMounted}
          onBoundsChanged={this.handleBoundsChanged}
          onSearchBoxMounted={this.handleSearchBoxMounted}
          bounds={bounds}
          onPlacesChanged={this.handlePlacesChanged}
          onMarkerClick={this.handleMarkerClick}
          onMarkerClose={this.handleMarkerClose}
          onMarkerDragEnd={this.handleDragEnd}
          markers={markers}
          classes={classes}
          dispatch={dispatch}
          addProp={addProp}
          zoom={zoom}
          withSearchBox={withSearchBox}
        />
      )
    );
  }
}

export default GoogleMapHandlerComponent;
