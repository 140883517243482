import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Activity from "components/DigiHapi/Activity";
import styles from "../../assets/jss/DigiHapi/pages/aboutStyle";

// @material-ui/icons
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
import Language from "@material-ui/icons/Language";

import { useSelector } from "react-redux";

const useStyles = makeStyles(styles);

export default function AboutPage(props) {
  const classes = useStyles();

  const { isFetching, serverVersion } = useSelector(
    state => state.versionReducer
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6} lg={4}>
        {isFetching ? (
          <Activity />
        ) : (
          <Card key="about">
            <CardHeader color="gray" stats icon>
              <CardIcon color="beige">
                <img
                  style={{ height: "100px" }}
                  src={require("assets/img/logo-rounded.png")}
                  alt="O’Camping logo"
                />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <small>{"O’Camping"}</small>
              </h3>
              <h4>
                <small>
                  {"Version client : "}
                  <span className={classes.blue}>
                    {require("../../../package.json").version}
                  </span>
                </small>
              </h4>
              <h4>
                <small>
                  {"Version serveur : "}
                  <span className={classes.blue}>{serverVersion}</span>
                </small>
              </h4>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.product}>
                <div>
                  <Phone />{" "}
                  <a href={`tel:+33 (0)4 50 44 50 33`}>
                    {"+33 (0)4 50 44 50 33"}
                  </a>
                  <br />
                </div>
                <div>
                  <Email />{" "}
                  <a href={`mailto:contact@ocamping.fr}`}>
                    {"contact@ocamping.fr"}
                  </a>
                  <br />
                </div>
                <div>
                  <Language />{" "}
                  <a
                    href={"https://www.ocamping.fr"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {"https://www.ocamping.fr"}
                  </a>
                  <br />
                </div>
              </div>
            </CardFooter>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
}
