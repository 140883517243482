/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";

// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/DigiHapi/components/footerStyle";
import { getImage } from "assets/Images";

const useStyles = makeStyles(styles);

export default function HomeFooter({ ...props }) {
  const { bgDarkGray } = props;
  const classes = useStyles();

  var footer = classNames({
    [classes.footer]: true,
    [classes.bdDarkGrayColor]: bgDarkGray,
    [classes.mt50]: bgDarkGray
  });

  return (
    <footer className={footer}>
      <div className={classes.footerTop}>
        <img
          src={getImage("logo")}
          alt="O’Camping logo"
          title="O’Camping logo"
        />
      </div>
      <hr />
      <div className={classes.container}>
        <div className={classes.copyright}>
          &copy; {`Copyright ${1900 + new Date().getYear()} - `}
          <strong>DigiHapi</strong>
        </div>
        <div className={classes.center}>
          <a
            className={classes.mentions}
            href="https://www.ocamping.fr/conditions-generales-utilisation"
            target="_blank"
          >
            C.G.U.
          </a>
          {" - "}
          <a
            href="https://www.ocamping.fr/charte-confidentialite"
            target="_blank"
          >
            Charte de confidentilité
          </a>
        </div>
      </div>
    </footer>
  );
}

HomeFooter.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  bgDarkGray: PropTypes.bool
};
